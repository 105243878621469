/**
 * 创建本地缓存对象
 * @param {string=} prefixKey -
 * @param {Object} [storage=localStorage] - sessionStorage | localStorage
 */

export default class Storage {
    storage;
    prefixKey;

    constructor(prefixKey = "", storage = localStorage) {
        this.storage = storage;
        this.prefixKey = prefixKey;
    }

    getKey(key) {
        return `${this.prefixKey}${key}`.toUpperCase();
    }

    /**
     * @description 设置缓存
     * @param {string} key 缓存键
     * @param {*} value 缓存值
     * @param expire
     */
    set(key, value, expire) {
        const stringData = JSON.stringify({
            value,
            expire: expire !== null ? new Date().getTime() + expire * 1000 : null,
        });
        this.storage.setItem(this.getKey(key), stringData);
    }

    /**
     * 读取缓存
     * @param {string} key 缓存键
     * @param {*=} def 默认值
     */
    get(key, def = null) {
        const item = this.storage.getItem(this.getKey(key));
        if (item) {
            try {
                const data = JSON.parse(item);
                const { value, expire } = data;
                // 在有效期内直接返回
                if (expire === null || expire >= Date.now()) {
                    return value;
                }
                this.remove(key);
            } catch (e) {
                return def;
            }
        }
        return def;
    }

    /**
     * 从缓存删除某项
     * @param {string} key
     */
    remove(key) {
        this.storage.removeItem(this.getKey(key));
    }

    /**
     * 清空所有缓存
     * @memberOf Cache
     */
    clear() {
        this.storage.clear();
    }
}

export const storage = new Storage("");
